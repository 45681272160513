.app {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
}

.left {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: stretch;
  align-content: stretch;
  width: 60%;
  height: 100vh;
}

.left video {
  flex: 1;
  width: 100%;
  height: 40vh;
}

.slideshow {
  flex: 1;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
  align-content: stretch;
  height: 60vh;
}

.slideshow figure {
  background-color: rgba(243, 54, 54, 0.4);  
  border-radius: 25px;
  padding: 5px;
}

.slideshow figcaption {
  color: black;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 25px;
  margin: 5px;
  padding: 4px;
  font-size: 24px;
  overflow-wrap: break-word;
}

.slideshow figcaption span {
  color: darkred;
}

.slideshow img {
  flex: 1;
  width: 100%;
  border-radius: 25px;
  max-height: 55vh;
}

.right {
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: stretch;
  align-content: stretch;
  width: 40%;
  margin-left: 20px;
  background-color: rgba(243, 54, 54, 0.4);
}

.logo {

}

.help {
  position: relative;
  overflow: hidden;
}

.help p {
  font-size: 24px;
  font-weight: bolder;
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  text-transform: uppercase;
  color: white;

  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%); 
  transform:translateX(100%);

  /* Apply animation to this element */ 
  -moz-animation: scroll-left 15s linear infinite;
  -webkit-animation: scroll-left 15s linear infinite;
  animation: scroll-left 15s linear infinite;
}

/* Move it (define the animation) */
@-moz-keyframes scroll-left {
  0% { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
}

@-webkit-keyframes scroll-left {
  0% { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
}

@keyframes scroll-left {
  0% { 
    -moz-transform: translateX(100%); /* Browser bug fix */
    -webkit-transform: translateX(100%); /* Browser bug fix */
    transform: translateX(100%); 
  }
  100% { 
    -moz-transform: translateX(-100%); /* Browser bug fix */
    -webkit-transform: translateX(-100%); /* Browser bug fix */
    transform: translateX(-100%); 
  }
}

.messages {
  flex: 1;
  font-size: 30px;
  overflow-wrap: break-word;
}

.messages p {
  color: black;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 25px;
  margin: 5px;
  padding: 4px;
}

.messages span {
  color: darkred;
}